<template>
  <div class="dept">
    <van-button class="dept-prev" @click="perv" block>返回上一级</van-button>
    <van-radio-group class="radio-group" v-model="selectId">
      <van-cell-group>
        <van-cell v-for="item in curList" :key="item.menuId">
          <template #title>
            <div style="display: flex">
              <van-radio
                :name="item.menuId"
                ref="checkboxes"
                @click="clickRadio(item)"
              />
              <div
                style="flex: 1; padding-left: 10px"
                @click="chooseDept(item)"
                class="custom-title"
              >
                {{ item.menuName }}
              </div>
            </div>
          </template>
          <template
            v-if="item.children && item.children.length > 0"
            #right-icon
          >
            <van-icon name="arrow" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div v-if="selected" class="faker-box"></div>
    <div class="btn-wrap" :class="selected ? 'selected' : ''" v-show="selected">
      <div class="btn-box">
        <van-button round block type="primary" @click="submit"
          >确认选择</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getChiTenant } from "@/api/web";
import { Toast } from "vant";

export default {
  data() {
    return {
      deptList: [],
      selectId: "",
      idList: [], // 存放单位层级
      tempList: [],
      selected: false, // 是否已有选择
      toast: null,
    };
  },
  computed: {
    curList() {
      // 当前显示列表， 如果第一层， 通过level 展示，后续通过pid 查询
      let list = [];
      if (this.idList.length === 0) {
        list = this.deptList.filter((item) => item.parentId === "-1");
      } else {
        list = this.tempList;
      }
      return list;
    },
    curId() {
      return this.idList.slice(-1);
    },
  },
  methods: {
    clickRadio(v) {
      this.selected = true;
      sessionStorage.setItem("selectItem", JSON.stringify(v));
    },

    findTempList(list) {
      for (let ele of list) {
        if (ele.parentId.includes(this.curId)) {
          const tempList = this.tempList.map((item) => item.menuId);
          if (!tempList.includes(ele.menuId)) {
            this.tempList.push(ele);
          }
        }
        if (ele.children && ele.children.length > 0) {
          this.findTempList(ele.children);
        }
      }
    },

    stop() {
      e.stopPropagation();
    },

    chooseDept(i) {
      if (i.children && i.children.length > 0) {
        this.idList.push(i.menuId);
        this.tempList = i.children;
      }
    },

    perv() {
      if (this.idList.length > 0) {
        this.idList.splice(-1, 1);
        this.tempList = [];
        this.findTempList(this.deptList);
      } else {
        // 跳转返回上一个页面
        this.$router.replace("account");
      }
    },

    submit() {
      this.$router.replace({
        name: "account",
      });
    },

    async getList() {
      let toast = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      const listRes = await getChiTenant();
      toast.clear();
      if (listRes.resp_code === 0) {
        this.deptList = listRes.datas;
      }
    },
  },

  created() {
    if (sessionStorage.getItem("selectItem")) {
      this.selected = true;
      this.selectId = JSON.parse(sessionStorage.getItem("selectItem")).menuId;
    }
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.dept {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .dept-prev {
    margin: 10px 0;
  }
  .radio-group {
    flex: 1;
    overflow: auto;
  }
  .faker-box {
    height: 88px;
  }
  .btn-wrap {
    position: fixed;
    bottom: -80px;
    left: 0;
    width: 100%;
    padding: 16px 0px;
    background: #ffffff;
    box-shadow: 0px -1px 0px rgba(230, 231, 235, 1);
    transition: all 2s;
    &.selected {
      bottom: 0;
    }
    .btn-box {
      padding: 0 16px;
    }
  }
}
</style>
